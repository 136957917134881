import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import bgLeft from "content/annoncement-left.png"
import bgRight from "content/annoncement-right.png"
import ArrowRight from "components/icons/arrowRight"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: #061627;
  background-image: url('${bgLeft}'), url('${bgRight}');
  background-repeat: no-repeat;
  background-position: 0rem 100%,100% 0;
  @media(max-width: 500px) {
    background-image: unset;
  }
`
const StyledContentWrapper = styled.div`
  text-align: center;
  max-width: 81.87rem;
  margin: 0 auto;
  padding: 0 1.375rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.05rem;
  padding-bottom: 1.05rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  span {
    margin-right: 0.5rem;
  }
  a {
    color: #87986a;
    display: flex;
    align-items: center;
    position: relative;
    svg {
      margin-left: 0.5rem;
    }
    &::before {
      transition: 500ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: #87986a;
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    padding: 0 3.375rem;
    height: 4rem;
  }
`


const Banner = () => {
  return (
    <StyledSection  id="banner">
      <StyledContentWrapper>
        <span>The private sale is live for all participants </span> <Link to="/private-sale"> click here to participate <ArrowRight color="#87986a" /></Link>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Banner
