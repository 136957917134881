import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledBtn = styled(Link)`
  display: flex;
  white-space: nowrap;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  user-select: none;
  height: 3rem;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    outline: none;
    transform: translateY(-1px);
    opacity: 0.8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  }
`

export default StyledBtn
