import React, { Children } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "./logo"
import External from "components/icons/external"
import 'balloon-css';
import Modal from "components/common/modal"
import Helmet from "react-helmet"


import ContentWrapper from "styles/contentWrapper"


const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 5rem;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    color: white;
    width: 100%;
    max-width: 100%;
    height: 8rem;
    display: flex;
    justify-content: space-between;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  align-items: center;
`

const Div = styled.div`
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
`

const StyledAnchorLink = styled(Link)`
  color: white;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  position: relative;
  padding: 0;
  cursor: pointer;
  margin: 1.09rem;
  &.underline::before {
    transition: 200ms ease-out;
    height: 0.1563rem;
    content: "";
    position: absolute;
    background-color: white;
    width: 0%;
    bottom: -0.225rem;
  }
  &:hover::before {
    width: 100%;
  }
  &.lang {
    opacity: 0.7;
  }
  &.active {
    opacity: 1;
  }
`

const StyledButton = styled.a`
  margin-left: 2rem;
  width: 17.45rem;
  height: 4.36rem;
  letter-spacing: 1.54px;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  &:first-of-type {
    margin-right: 0.5rem;
  }
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
  }
`


const Navbar = ({children, home, isModalOpen, closeModal}) => {
  return (
    <StyledHeaderWrapper home={home}>
      <Helmet bodyAttributes={{ class: isModalOpen ? "blur" : "" }} />
      <Modal closeModal={closeModal} shown={isModalOpen}/>

      <StyledContentWrapper>
        <Nav>
          <Div>
            <Link
              style={{
                display: 'flex',
                marginRight: '2rem',
              }}
              to={"/"}
            >
              <Logo />
            </Link>
          </Div>
          <Div>
            <StyledAnchorLink className="underline" to="/#pools">
              IDO Projects
            </StyledAnchorLink>
            <StyledAnchorLink className="underline" to="/staking">
              Staking
            </StyledAnchorLink>
            <StyledAnchorLink className="underline" target="_blank" rel="nofollow" as="a"  href="https://forms.zohopublic.eu/plungepadio/form/ApplicationforanIDO/formperma/NBQbo7aqdwTVBSK8Ov2D99tWzfHADdp7i36bFhS3LJM">
              Get Incubated
            </StyledAnchorLink>
            <StyledAnchorLink className="underline" target="_blank" rel="nofollow" as="a"  href="https://workdrive.zohopublic.eu/file/7290q96ead462032a461b9bad0044f6a33444">
              Litepaper
            </StyledAnchorLink>
          </Div>
        </Nav>
        <Div>
          {children}
        </Div>
      </StyledContentWrapper>
    </StyledHeaderWrapper>
  )
}

export default Navbar
