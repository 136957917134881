import React, {useState} from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import External from "components/icons/external"


const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  overflow-y: auto;
  @media (min-width: 1200px) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  min-height: 100vh;
  width: 95%;
  overflow-y: scroll;
  text-align: left;
  padding: 12rem 1rem 2rem 1rem;
  position: relative;
  right: 0;
  margin-left: auto;
  .nav-drop {
    font-size: 1.5rem;
    color: #00495d;
    font-weight: bold;
    padding-bottom: 1.3rem;
    display: flex;
    justify-content: space-between;
  }
  .link {
    text-align: center;
    font-size: 1.5rem;
    color: #00495d;
    font-weight: bold;
    padding-bottom: 2rem;
    span {
      font-size: 1rem;
      opacity: 0.6;
      font-style: italic;
    }
  }
`


const StyledButton = styled.a`
  color: #212121;
  background-color: #fff;
  border-color: #ffeaef;
  padding: 0.7rem 1.2rem;
  transition: all .5s ease-in-out;
  cursor: pointer;
  font-weight: 700;
  font-size: .688rem;
  line-height: 1.3rem;
  letter-spacing: 1.54px;
  border-radius: 100px;
  border: 1px solid;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 600;
  &:first-of-type {
    margin-right: 0.5rem;
  }
`

const StyledButtonEmpty = styled(StyledButton)`
  font-size: 1.2rem;
  border-color: transparent;
  color: #fff;
  background: rgb(0, 169, 224);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-top: -3px;
  }
`



const SideBar = ({ open, setOpen }) => {
  const [openedDropDown, setOpenedDropDown] = useState('')
  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          <Link className="link" as="a" target="blank" rel="nofollow"  href="https://forms.zohopublic.eu/plungepadio/form/ApplicationforanIDO/formperma/NBQbo7aqdwTVBSK8Ov2D99tWzfHADdp7i36bFhS3LJM" disabled>Get Incubated</Link >
          <Link className="link" to="/staking">Staking <span></span></Link >
          <Link className="link" href="https://workdrive.zohopublic.eu/file/7290q96ead462032a461b9bad0044f6a33444" target="_blank">Litepaper <span></span></Link >

{/*           <StyledButtonEmpty href="https://workdrive.zohopublic.eu/external/8d985b6aa3bde9fff32a19c704ce0a71e271e057b4282536a84b629554c53b7a" target="_blank" rel="nofollow">
            Litepaper <External color="#fff"/>
          </StyledButtonEmpty> */}
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SideBar
