import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import logo from 'content/images/logo.png'
import { siteShortTitle } from "../../config"

const StyledLogo = styled.img`
  position: relative;
  z-index: 0;
  width: auto;
  height: auto;
  width: 12.7rem;
  /* Disable effects when sidebar is open */
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  @media(max-width: 600px) {
    //display: none;
    width: 12rem;
  }
`

const StyledIcon = styled.img`
  height: 3rem;
  width: auto;
`

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Logo = ({ size, color }) => (
  <StyledWrapper>
    <StyledLogo src={logo} alt="icon" />
  </StyledWrapper>
)

Logo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
