import React, { useState, useEffect } from "react"
import styled from "styled-components"
import OutsideClickHandler from 'react-outside-click-handler';
import walletConnectLogo from "content/images/wallet-connect.svg"
import metamaskLogo from "content/images/metamask.svg"
import timesIcon from "content/images/times.svg"
import { injected } from "components/wallet/connectors"
import { useWeb3React } from "@web3-react/core"


const StyledModal = styled.div`
    font-family: ${({ theme }) => theme.fonts.poppins};
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: rgba(14, 5, 53, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    pointer-events: none;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
    z-index: 100000000;
    &.shown {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
    }
    & > div {
      position: relative;
      padding: 1.5rem;
      max-width: 80%;
      width: 100%;
      background: #27136D;
      border: 1px solid rgba(255, 255, 255, 0.12);
      box-sizing: border-box;
      border-radius: 1.4rem;
      transform: scale(1.2);
      transition: all 0.3s ease-in-out;
      .modal__header {
        font-size: 1.3rem;
        line-height: 1.7rem;
        font-weight: 600;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        img {
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .modal__body {
        ul {
          list-style: none;
          padding: 0;
          li {
            padding: 1rem;
            background: rgba(255, 255, 255, 0.08);
            border-radius: 1rem;
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
            &:nth-of-type(2) {
              cursor: not-allowed;
              opacity: 0.5;
              pointer-events: none;
              position: relative;
              small {
                position: absolute;
                bottom: -0.3rem;
                left: 1rem;
                font-size: 0.6rem;
                color: #D33636;
              }
            }
          }
        }
      }
      .modal__footer {
        text-align: center;
        margin-top: 2rem;
        div:first-of-type {
          color:#D14ABD;
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
        a {
          color: #A5ACC7;
          font-size: 1.1rem;
          line-height: 1.4rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @media (min-width: 1200px) {
        max-width: 500px;
        width: 100%;
      }
    }

`


const Modal = props => {
  const { active, account, library, connector, activate, deactivate } = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, [])
  async function connect() {
    console.log('connect')
    try {
      await activate(injected)
    } catch (ex) {
      console.log(ex)
    }
  }
  return (
    <StyledModal  id="Modal" className={props.shown ? "shown" : ""}>
      <OutsideClickHandler onOutsideClick={props.closeModal} className="modal-box">
          <div className="modal__header">
            <span>Connect Wallet</span>
            <img src={timesIcon} alt="times" onClick={props.closeModal} />
          </div>
          <div className="modal__body">
            <ul>
              <li onClick={connect}><span>Metamask</span><img src={metamaskLogo} alt="metamask logo" /></li>
              <li><span>Wallet Connect</span><img src={walletConnectLogo} alt="wallet connect logo" /><small>Coming soon</small></li>
            </ul>
          </div>
          <div className="modal__footer">
            <div>Need help ?</div>
            <a href="https://metamask.io/faqs" target="blank">Learn more about setting up wallet</a>
          </div>
      </OutsideClickHandler>
    </StyledModal>
  )
}


export default Modal
