import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import ContentWrapper from "styles/contentWrapper"
import Twitter from "components/icons/twitter"
import Medium from "components/icons/medium"
import Telegram from "components/icons/telegram"
import Ethscan from "components/icons/ethscan"
import BSC from "components/icons/bsc"
import Logo from "./logo"



const FooterSection = styled.footer`
  position: relative;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    color: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 0;
      padding:0;
      z-index: 10;
      li {
        display: flex;
        margin: 1rem 0;
        z-index: 10;
        a {
          margin: 0 0.5rem;
          font-size: 1.3rem;
          display: flex;
          width: 3rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255,255,255,0.12);
          border-radius: 50%;
          span {
            font-size: 1rem;
            margin-left: 0.2rem;
          }
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
    @media (min-width: 1200px) {
      flex-direction: row;
      ul {
        flex-direction: row;
        justify-content: center;
        margin-top: 2rem;
        li {
          margin: 0;
        }
      }
    }
  }
`



const Footer = () => {
    return (
      <FooterSection>
        <StyledContentWrapper>
          <div className="logo">
          <Link
              style={{
                display: 'flex',
                marginRight: '2rem',
              }}
              to={"/"}
            >
              <Logo />
            </Link>
          </div>
          <ul>
            <li>
              <a href="https://t.me/plungepad" target="_blank" rel="nofollow"><Telegram color="white"/></a>
              <a href="https://twitter.com/Plungepad" target="_blank" rel="nofollow"><Twitter color="white"/></a>
              <a href="https://medium.com/@PlungePad" target="_blank" rel="nofollow"><Medium color="white"/></a>
              <a href="https://arbiscan.io/address/0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63" target="_blank" rel="nofollow"><Ethscan /></a>
              <a href="https://bscscan.com/address/0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63" target="_blank" rel="nofollow"><BSC color="#823EF6"/></a>              

            </li>
          </ul>
        </StyledContentWrapper>
      </FooterSection>
    )
}

export default Footer;
