import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import "fontsource-roboto/400.css"
import "fontsource-roboto/700.css"

import { lightTheme, darkTheme } from "../styles/theme"
import { useDarkMode } from "../hooks"
import Context from "../context"
import GlobalStyle from "../styles/globalStyle"
import Banner from "components/common/banner"
import Header from "./header"
import Footer from "./footer"
import SplashScreen from "../components/splashScreen"
import { useCookieBar } from "../../config"

// https://medium.com/@chrisfitkin/how-to-smooth-scroll-links-in-gatsby-3dc445299558
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const StyledLayoutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-image: ${props => props.home ? "unset" : "radial-gradient(50% 50% at 50% 50%, rgba(130, 62, 246, 0.15) 0%, rgba(255, 255, 255, 0) 100%)"};
`

const Layout = ({ children, home, privateSale, currentChain }) => {
  const { isIntroDone } = useContext(Context).state
  // Enables dark mode if the user's OS has an active dark theme
  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : lightTheme

  return (
    <>
       {/* <Banner /> */}
        <StyledLayoutWrapper id="layout-wrapper" home={home} dataUseCookieBar={useCookieBar}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {isIntroDone ? (
              <>
                <Header home={home} currentChain={currentChain} privateSale={privateSale}/>
                <main id="main-content">{children}</main>
                {home ? <Footer /> : null}
              </>
            ) : (
              <SplashScreen />
            )}
          </ThemeProvider>
        </StyledLayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
