import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import Modal from "components/common/modal"

import OutsideClickHandler from 'react-outside-click-handler';
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { injected } from "components/wallet/connectors"
import { useWeb3React } from "@web3-react/core"
import { detectMobileAndTablet, isSSR } from "utils"
import ContentWrapper from "styles/contentWrapper"
import StyledBtn from "styles/button"
import { UnsupportedChainIdError } from '@web3-react/core'

import Logo from "./logo"
import logoIcon from "content/icon.png"
import ethIcon from "content/images/eth_icon.svg"
import bscIcon from "content/bscicon.svg"
import maticIcon from "content/matic-icon.png"
import arbiIcon from "content/arbitrum.svg"

import errorIcon from "content/images/error.svg"


import chevronIcon from "content/images/chevron.svg"

import SideBar from "./sidebar"
import Navbar from "./navbar"

const networks = [
  {
    name: "arbitrum",
    blockExplorerUrls: "https://arbiscan.io",
    chainId: "0xa4b1",
    params: {
      chainName: "arbitrum",
      chainId: "0xa4b1",
      rpcUrls: ['https://arb1.arbitrum.io/rpc'],
      nativeCurrency: {
        symbol: "ETH", // 2-6 characters long
        decimals: 18,
      }
    }
  },
  {
    name: "bsc",
    blockExplorerUrls: "https://bscscan.com",
    chainId: "0x38",
    params: {
      chainName: "binance smart chain",
      chainId: "0x38",
      rpcUrls: ['https://bsc-dataseed.binance.org'],
      nativeCurrency: {
        symbol: "BNB", // 2-6 characters long
        decimals: 18,
      }
    }
  }
]

const StyledHeader = motion.custom(styled.header`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight};
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
/*   &.mobile {
    background: ${props => props.privateSale ? '#563cc9': 'unset'};
  } */
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

// https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const StyledBurger = styled.button`
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: black;
    border-radius: 0.625rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;
    background: ${({ open }) => (open ? "black" : "white")};;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`
const StyledButtonEmpty = styled(StyledBtn)`
  color: #D14ABD;
  background: transparent;
  border: 1px solid #D14ABD;
  &.error {
    background: #D33636;
    color: white;
    font-weight: 400;
    img {
      margin-right: 0.5rem;
    }
  }
  &.connected {
    border: none;
    margin-left: 0.5rem;
    transition: all .5s ease-in-out;
    cursor: pointer;
    letter-spacing: 1.54px;
    display: inline-block;
    font-size: 0.9rem;
    color: white;
    line-height: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0!important;
    width: unset!important;
    &:first-of-type {
      margin-right: 0.5rem;
    }
    div {
      padding: 0.5rem 1.2rem;
    }
    .disconnected {
      padding: 1rem 1.5rem;
    }
    .button-content {
      padding: 0;
      display: flex;
      height: 100%;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 4.36rem;
      div:first-of-type {
        border-right: 1px solid rgba(36, 42, 50, 0.2);
      }
      div {
        &:first-of-type {
          padding: 1rem 0.5rem 1rem 1.2rem;
          border-bottom-left-radius: 4.36rem;
          border-top-left-radius: 4.36rem;
        }
        &:last-of-type {
          padding: 1rem 1.2rem 1rem 1.2rem;
          border-radius: 4.36rem;
          background: rgba(255, 255, 255, 0.2);
        }
        align-items: center;
        display: flex;
        @media (min-width: 767px) {
          &:last-of-type {Ò
            padding: 1rem 0.5rem 1rem 1.2rem
          }
        }
      }
    }
  }
`

const StyledDropdownWrapper = styled.div`
  position: relative;
`

const StyledButtonDropdown = styled(StyledBtn)`
  color: white;
  background: rgba(255, 255, 255, 0.2);
  padding-left: 0.5rem;
  border: none;
  img {
    transition: transform .4s;
  }
  .rotated {
    transition: transform .4s;
    transform: rotate(180deg);
  }
`

const StyledMenu = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 4rem;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px;
  white-space: normal;
  visibility: visible;
  width: auto;
  min-width: 18rem;
  overflow: auto;
  background: #27136D;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
  .menu-title {
    font-size: 1rem;
    opacity: 0.5;
    color: white;
    line-height: 1.2rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    li {
      padding: 0.7rem 0.7rem;
      border-radius: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }
      .icon-wrapper {
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.3rem;
        height: 2.3rem;
        margin-right: 0.7rem;
      }
    }
    li.active {
      background: rgba(255, 255, 255, 0.08);
    }
  }
`

const StyledButton = styled.button`
  margin-left: 0.5rem;
  transition: all .5s ease-in-out;
  cursor: pointer;
  letter-spacing: 1.54px;
  display: inline-block;
  font-size: 0.9rem;
  color: white;
  line-height: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0!important;
  width: unset!important;
  &:first-of-type {
    margin-right: 0.5rem;
  }
  div {
    padding: 0.5rem 1.2rem;
  }
  .disconnected {
    padding: 1rem 1.5rem;
  }
  .button-content {
    padding: 0;
    display: flex;
    height: 100%;
    div:first-of-type {
      border-right: 1px solid rgba(36, 42, 50, 0.2);
      background: rgba(36, 42, 50, 0.2);
    }
    div {
      &:first-of-type {
        padding: 1rem 0.5rem 1rem 1.2rem;
        border-bottom-left-radius: 4.36rem;
        border-top-left-radius: 4.36rem;
      }
      &:last-of-type {
        padding: 1rem 1.2rem 1rem 0.5rem;
      }
      align-items: center;
      display: flex;
    }
  }
`

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`


const Header = ({ home, privateSale, currentChain }) => {
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [tried, setTried] = useState(false)
  const [balance, setBalance] = useState(null)
  const [selectedNetWork, setSelectedNetWork] = useState('arbitrum')
  const { active, account, library, connector, activate, deactivate, error} = useWeb3React()
  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  useEffect(() => {
    if(library && library.currentProvider ) {
      if(library.currentProvider.networkVersion == 56) {
        setSelectedNetWork('bsc')
      }
      if(library.currentProvider.networkVersion == 42161) {
        setSelectedNetWork('arbitrum')
      } 
    }
  }, [library])

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
  }, [])

  // Required for animation - start after the splashScreen sequence is done
  const controls = useAnimation()

  async function switchNetwork(chain) {
    try {
      if(!isSSR) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain.chainId }],
        });
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          if(!isSSR) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              blockExplorerUrls: chain.blockExplorerUrls,
              params: [chain.params],
            });
          }
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }

  function toggleDropdown () {
    window.setTimeout(() => {
      setDropDownOpen(!dropDownOpen)
    }, 1);
  }

  function selectNetWork(value) {
    const chain = networks.filter(network => network.name === value)[0];
    switchNetwork(chain).then(() => {
      window.location.reload()
    }).catch(() => {
      window.location.reload()
    })
    setSelectedNetWork(value)
    setDropDownOpen(false)
  }

  function closeModal() {
    setModalOpen(false)
  }

  if(active) {
    library.eth.getBalance(account).then(res =>  setBalance((res/(10**18)).toFixed(2)))
  }

  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError ||
    (currentChain && currentChain.length === 1 && library && library.currentProvider.networkVersion != currentChain[0] || 
       (library && currentChain && currentChain.length === 2 && library.currentProvider.networkVersion != currentChain[0] &&
         library.currentProvider.networkVersion != currentChain[1]))

  console.log('selectedNetWork', selectedNetWork)
  let navigation
  if (detectMobileAndTablet(windowWidth)) {
    navigation = (
      <StyledHeader className="mobile" privateSale={privateSale}>
        {/* add blur class to body when sidebar is opened */}
        <Helmet bodyAttributes={{ class: open || modalOpen ? "blur" : "" }} />
        {modalOpen ? <Modal closeModal={closeModal} shown={modalOpen}/> : null }
        <StyledContentWrapper>
          <StyledFlex>
            <StyledDropdownWrapper>
              <StyledButtonDropdown as="button" onClick={toggleDropdown}>
                { selectedNetWork === 'arbitrum' && (<><span class="circle"><img src={arbiIcon}  alt="eth icon" with="13" height="22"></img></span></>)}
                { selectedNetWork === 'bsc' && (<><span class="circle"><img src={bscIcon}  alt="eth icon" with="13" height="22"></img></span></>)}
                { selectedNetWork === 'ethereum' && (<><span class="circle"><img src={ethIcon}  alt="eth icon" with="13" height="22"></img></span></>)}
                { selectedNetWork === 'polygon' && (<><span class="circle"><img src={maticIcon}  alt="eth icon" with="13" height="22"></img></span></>)}

                <img src={chevronIcon} className={dropDownOpen ? "rotated ml-05" : "ml-05"} alt="chevron icon" with="10" height="5"></img>
              </StyledButtonDropdown>
              {dropDownOpen ? (
                <OutsideClickHandler
                  onOutsideClick={toggleDropdown}
                >
                <StyledMenu>
                  <div className="menu-title">
                    Select Network
                  </div>
                  <ul>
                    <li className={selectedNetWork === 'arbitrum' ? 'active' : ''} onClick={() => selectNetWork('arbitrum')}>
                      <div className="icon-wrapper"><img src={arbiIcon} alt="eth icon" with="13" height="22"></img></div>
                      <div>Arbitrum</div>
                    </li>
                    <li className={selectedNetWork === 'bsc' ? 'active' : ''} onClick={() => selectNetWork('bsc')}>
                      <div className="icon-wrapper"><img src={bscIcon} alt="eth icon" with="13" height="22"></img></div>
                      <div>BSC</div>
                    </li>
                    <li className={selectedNetWork === 'ethereum' ? 'active disabled' : 'disabled'} onClick={() => selectNetWork('ethereum')}>
                      <div className="icon-wrapper"><img src={ethIcon} alt="eth icon" with="13" height="22"></img></div>
                      <div>Ethereum</div>
                    </li>
                    <li className={selectedNetWork === 'polygon' ? 'active disabled' : 'disabled'} onClick={() => selectNetWork('polygon')}>
                      <div className="icon-wrapper"><img src={maticIcon} alt="eth icon" with="13" height="22"></img></div>
                      <div>Polygon</div>
                    </li>
                  </ul>
                </StyledMenu>
                </OutsideClickHandler>
              )
              :
                null
              }
            </StyledDropdownWrapper>
            {isUnsupportedChainIdError ? (
              <StyledButtonEmpty
                as="button"
                className="ml-05 error"
              >
                <img src={errorIcon} alt="Wrong network" with="20" height="20" /> Wrong network
              </StyledButtonEmpty>
            ):
            (
              <StyledButtonEmpty
                as="button"
                onClick={() => setModalOpen(true)}
                className={active ? 'ml-05 connected' : 'ml-05'}
                
              >
                {active ? 
                  <div className="button-content">
                    <div>{account.slice(0, 6)}...{account.slice(account.length - 4, account.length)}</div>
                  </div>
                  :
                  <div className="disconnected">Connect wallet</div>
                }
              </StyledButtonEmpty>
            )}
          </StyledFlex>
          {/* <button>connect wallet</button> */}
          <StyledBurger
            // aria-controls="#sidebar"
            aria-label="button"
            open={open}
            onClick={() => setOpen(!open)}
          >
            <div />
            <div />
            <div />
          </StyledBurger>
          <SideBar id="sidebar" open={open} setOpen={setOpen} />
        </StyledContentWrapper>
      </StyledHeader>
    )
  } else {
    navigation = (
      <Navbar home={home} isModalOpen={modalOpen} closeModal={closeModal}>
        <StyledDropdownWrapper>
          <StyledButtonDropdown as="button" onClick={toggleDropdown}>
            { selectedNetWork === 'arbitrum' && (<><span class="circle"><img src={arbiIcon}  alt="eth icon" with="13" height="22"></img></span>Arbitrum</>)}
            { selectedNetWork === 'bsc' && (<><span class="circle"><img src={bscIcon}  alt="eth icon" with="13" height="22"></img></span>BSC</>)}
            { selectedNetWork === 'ethereum' && (<><span class="circle"><img src={ethIcon}  alt="eth icon" with="13" height="22"></img></span>Ethereum</>)}
            { selectedNetWork === 'polygon' && (<><span class="circle"><img src={maticIcon}  alt="eth icon" with="13" height="22"></img></span>Polygon</>)}

            <img src={chevronIcon} className={dropDownOpen ? "rotated ml-05" : "ml-05"} alt="chevron icon" with="10" height="5"></img>
          </StyledButtonDropdown>
          {dropDownOpen ? (
            <OutsideClickHandler
              onOutsideClick={toggleDropdown}
            >
            <StyledMenu>
              <div className="menu-title">
                Select Network
              </div>
              <ul>
                <li className={selectedNetWork === 'arbitrum' ? 'active' : ''} onClick={() => selectNetWork('arbitrum')}>
                  <div className="icon-wrapper"><img src={arbiIcon} alt="eth icon" with="13" height="22"></img></div>
                  <div>Arbitrum</div>
                </li>
                <li className={selectedNetWork === 'bsc' ? 'active' : ''} onClick={() => selectNetWork('bsc')}>
                  <div className="icon-wrapper"><img src={bscIcon} alt="eth icon" with="13" height="22"></img></div>
                  <div>BSC</div>
                </li>
                <li className={selectedNetWork === 'ethereum' ? 'active disabled' : 'disabled'} onClick={() => selectNetWork('ethereum')}>
                  <div className="icon-wrapper"><img src={ethIcon} alt="eth icon" with="13" height="22"></img></div>
                  <div>Ethereum</div>
                </li>
                <li className={selectedNetWork === 'polygon' ? 'active disabled' : 'disabled'} onClick={() => selectNetWork('polygon')}>
                  <div className="icon-wrapper"><img src={maticIcon} alt="eth icon" with="13" height="22"></img></div>
                  <div>Polygon</div>
                </li>
              </ul>
            </StyledMenu>
            </OutsideClickHandler>
          )
          :
            null
          }
        </StyledDropdownWrapper>
        {isUnsupportedChainIdError ? (
          <StyledButtonEmpty
            as="button"
            className="ml-05 error"
            
          >
            <img src={errorIcon} alt="Wrong network" with="20" height="20" /> Wrong network
          </StyledButtonEmpty>
        ):
        (
          <StyledButtonEmpty
            as="button"
            onClick={() => setModalOpen(true)}
            className={active ? 'ml-05 connected' : 'ml-05'}
            
          >
            {active ? 
              <div className="button-content">
                <div>{balance !== null && `${balance} ${library.currentProvider.networkVersion == 56 ? 'BNB' : 'ETH'} `}</div>
                <div>{account.slice(0, 6)}...{account.slice(account.length - 4, account.length)}</div>
              </div>
              :
              <div className="disconnected">Connect wallet</div>
            }
          </StyledButtonEmpty>
        )}
        
         {/*  <StyledButton to="/#pools">
            <div>IDO sale</div>
          </StyledButton> */}
        </Navbar>)
  }

  return (
    <>
      { windowWidth ? navigation : <StyledHeader></StyledHeader>}
    </>
  )
}

export default Header