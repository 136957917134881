import { createGlobalStyle } from "styled-components"
import modernNormalize from "styled-modern-normalize"
import montserrat from "../fonts/montserrat.woff"
import poppins from "../fonts/poppins.woff"
import kumbh from "../fonts/kumbh.woff"
import ellipsis from "content/images/ellipsis.png"


const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
  // Import normalize.css
  @keyframes pulse {
    0% {
      transform: scale(0.99);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
  
    70% {
      transform: scale(1.01);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  
    100% {
      transform: scale(0.99);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes float {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(100px, -100px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  
  @keyframes spin {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @font-face {
    font-family: kumbh;
    src: url(${kumbh}) format("woff");
  }
  @font-face {
    font-family: montserrat;
    src: url(${montserrat}) format("woff");
    font-style: normal;
  }


  @media (min-width:1200px) {
    :root {
      font-size: 12px
    }
  }

  @media (min-width:1300px) {
    :root {
      font-size: 14px
    }
  }

  @media (min-width:1400px) {
    :root {
      font-size: 14px
    }
  }

  @media (min-width:1500px) {
    :root {
      font-size: 15px
    }
  }

  @media (min-width:1600px) {
    :root {
      font-size: 16px
    }
  }

  @media (min-width:1700px) {
    :root {
      font-size: 17px
    }
  }

  @media (min-width:1800px) {
    :root {
      font-size: 18px
    }
  }

  @media (min-width:1900px) {
    :root {
      font-size: 19px
    }
  }
  
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    color: white;
    background-color: #220F5E;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    line-height: 2.14rem;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    &.blur {
        overflow: hidden;
        #___gatsby #main-content > * {
          filter: blur(5px) ;
          transition: all .3s ease-out;
          pointer-events: none;
          user-select: none;
        }
      }
    }
    &.splashScreen {
          position: fixed;
          overflow: hidden;
    }
  }
  .small {
    font-size: 0.8rem;
    opacity: 0.8;
    line-height: 1.2rem;
  }
  .plunge-button {
    padding: 18px;
    width: 100%;
    text-align: center;
    border-radius: 4.36rem;
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: #823EF6;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
  }
  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    cursor: pointer;
    &:hover,
    &:focus {
      outline: 0;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.375rem;
    color: white;
    font-family: ${({ theme }) => theme.fonts.kumbh};
    font-style: normal;
    margin: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 3.71rem; // 66/13.75
      line-height: 5.42rem;
    }
  }

  h2 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      color: #252525;
      margin: 0;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fonts.kumbh};
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 4rem;
          line-height: 5rem;

      }
  }

  h3 {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: white;
    margin-bottom: 3rem;
  }

  h4 {
      font-size: 1.1875rem;
      line-height: 1.8rem;
      font-weight: 400;
      margin: 0;
  }

  hr {
    margin: 3rem auto;
    border-width: .05rem;
    color: ${({ theme }) => theme.colors.tertiary};
    opacity: 0.1;
  }

  .mt-05 {
    margin-top: 0.5rem;
  }

  .mr-05 {
    margin-right: 0.5rem;
  }

  .mb-05 {
    margin-bottom: 0.5rem;
  }

  .ml-05 {
    margin-left: 0.5rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .mb-1 {
    margin-bottom: 1rem;
  }

  .ml-1 {
    margin-left: 1rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mr-2 {
    margin-right: 2rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .ml-2 {
    margin-left: 2rem;
  }
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .marqee__inner {
    @keyframes marquee {
      0% { left: 0; }
      100% { left: -100%; }
    }
  }

  @-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
  }

  @keyframes spin {
      0% {
          transform: rotate(0deg)
      }
      to {
          transform: rotate(1turn)
      }
  }
  .loader {
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
  }

  
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .view {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    perspective: 400;
    z-index: 0;
    opacity: 0.4;
    width: 300px;
    height: 300px;
  }
  .view.bottom {
    bottom: 0;
    right: 0;
    left: unset;
    top: unset;
  }
  .plane {
    width: 300px;
    height: 300px;
    transform-style: preserve-3d;
 }
  .main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotateX(60deg) rotateZ(-30deg);
    animation: rotate 20s infinite linear;
 }
  .main .circle {
    width: 300px;
    height: 300px;
    position: absolute;
    transform-style: preserve-3d;
    border-radius: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 60px #49e9fb, inset 0 0 60px #49e9fb;
 }
  .main .circle::before, .main .circle::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 10%;
    height: 10%;
    border-radius: 100%;
    background: #49e9fb;
    box-sizing: border-box;
    box-shadow: 0 0 60px 2px #49e9fb;
 }
  .main .circle::before {
    transform: translateZ(-90px);
 }
  .main .circle::after {
    transform: translateZ(90px);
 }
  .main .circle:nth-child(1) {
    transform: rotateZ(72deg) rotateX(63.435deg);
 }
  .main .circle:nth-child(2) {
    transform: rotateZ(144deg) rotateX(63.435deg);
 }
  .main .circle:nth-child(3) {
    transform: rotateZ(216deg) rotateX(63.435deg);
 }
  .main .circle:nth-child(4) {
    transform: rotateZ(288deg) rotateX(63.435deg);
 }
  .main .circle:nth-child(5) {
    transform: rotateZ(360deg) rotateX(63.435deg);
 }
  @keyframes rotate {
    0% {
      transform: rotateX(0) rotateY(0) rotateZ(0);
   }
    100% {
      transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
   }
 }

.Toastify__toast-body {
  font-size: 1rem;
}

.circle {
  background: #E7E7E7;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.ellipsis-bg {
  background-image: url(${ellipsis});
  background-repeat: no-repeat;
}

.tab-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin-top: 4.5rem;
  cursor: pointer;
  padding-bottom: 1rem;
  border-bottom: 1px solid #D14ABD;
  .tab-list-item {
    white-space: nowrap;
    margin: 0;
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
    font-size: 0.8rem;
    margin-top: 1rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .05rem;
    position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: .9rem;
      &:last-of-type {
        margin-right: 0;
      }
      margin: 0 1rem;
    }
    &.tab-list-active {
      color: red;
    }
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: #D14ABD;
      width: 0%;
      bottom: -1rem;
    }
    &:hover::before {
      width: 100%;
    }
    &.tab-list-active::before {
      width: 100%;
    }
  }
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`

export default GlobalStyle
