import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import image from "content/og-image.png"
import favicon from "content/icon.png"

const SEO = ( ) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title="Plunge Pad | The first and fairest launchpad built on Arbitrum"
      titleTemplate={`%s`}
      link={[{ rel: 'canonical', key: "https://plungepad.io/", href: "https://plungepad.io/" }]}
      meta={[
        {
          name: `description`,
          content: "Plunge Pad is the first launchpad dedicated to launching projects on Arbitrum. It’s a platform that grants every holder of our exclusive token access to pre-sales of projects selected for our launchpad. In addition, it will serve as a launchpad for the carefully curated and vetted token offerings that want to launch on Arbitrum to raise capital and drive initial liquidity in a decentralised and interoperable manner.",
        },
        {
          name: `msapplication-TileImage`,
          content: `${favicon}`,
        },
        {
          name: `keywords`,
          content: "plunge pad, plunge, lauchpad, arbitrum launchpad, plg, arbitrum",
        },
        {
          property: `og:title`,
          content: "Plunge Pad | The first and fairest launchpad built on Arbitrum",
        },
        {
          property: `og:url`,
          content: "https://plungepad.io/",
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:site_name`,
          content: "plungepad",
        },
        {
          property: `og:description`,
          content: "Plunge Pad is the first launchpad dedicated to launching projects on Arbitrum. It’s a platform that grants every holder of our exclusive token access to pre-sales of projects selected for our launchpad. In addition, it will serve as a launchpad for the carefully curated and vetted token offerings that want to launch on Arbitrum to raise capital and drive initial liquidity in a decentralised and interoperable manner.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
      ]}
    />
  )
}


export default SEO
