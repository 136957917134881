import React from "react"
import PropTypes from "prop-types"

const Ethscan = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124.983 140.32"
      x="0px"
      y="0px"
      role="img"
      width="1em"
      height="1em"
    >
        <g xmlns="http://www.w3.org/2000/svg" id="symbol" transform="translate(-177.491 -53.193)">
          <path id="Path_153" data-name="Path 153" d="M266.978,128.665l10.305-17.485,27.776,43.262.013,8.3-.091-57.131a4.3,4.3,0,0,0-1.99-3.428L252.984,73.42a4.408,4.408,0,0,0-3.821.018,4.352,4.352,0,0,0-.448.259l-.174.11L200,101.935l-.189.085a4.417,4.417,0,0,0-.717.418,4.29,4.29,0,0,0-1.729,2.731,4.419,4.419,0,0,0-.062.505l.076,46.556,25.872-40.1c3.257-5.317,10.354-7.03,16.942-6.937l7.732.2-45.56,73.064,5.371,3.092,46.106-76.083,20.379-.074-45.987,78L247.4,194.422l2.29,1.317a4.4,4.4,0,0,0,3.087.061l50.71-29.387-9.7,5.618Zm3.932,56.627-19.356-30.379,11.815-20.049,25.42,40.066Z" transform="translate(-11.186 -11.178)" fill="#2d374b"/>
          <path id="Path_154" data-name="Path 154" d="M321.883,235.122,341.239,265.5l17.88-10.362L333.7,215.073Z" transform="translate(-81.515 -91.387)" fill="#28a0f0"/>
          <path id="Path_155" data-name="Path 155" d="M395.4,212.248l-.013-8.3-27.776-43.262L357.3,178.169l26.814,43.366,9.7-5.618a4.3,4.3,0,0,0,1.587-3.129Z" transform="translate(-101.511 -60.683)" fill="#28a0f0"/>
          <path id="Path_156" data-name="Path 156" d="M177.491,212.312l13.691,7.889,45.56-73.064-7.732-.2c-6.588-.093-13.685,1.619-16.942,6.937l-25.872,40.1-8.7,13.373v4.969Z" transform="translate(0 -52.917)" fill="#fff"/>
          <path id="Path_157" data-name="Path 157" d="M287.75,147.406l-20.378.074-46.106,76.083,16.115,9.279,4.382-7.433Z" transform="translate(-24.713 -53.187)" fill="#fff"/>
          <path id="Path_158" data-name="Path 158" d="M302.474,94.114a12.98,12.98,0,0,0-6.093-10.435L245.719,54.545a13.178,13.178,0,0,0-11.624,0c-.423.213-49.268,28.542-49.268,28.542a13.016,13.016,0,0,0-1.94,1.148,12.881,12.881,0,0,0-5.4,9.854v60.338l8.7-13.373L186.12,94.5a4.325,4.325,0,0,1,1.791-3.236c.23-.165,49.909-28.921,50.067-29a4.408,4.408,0,0,1,3.821-.018l50.007,28.765a4.3,4.3,0,0,1,1.99,3.428v57.672a4.2,4.2,0,0,1-1.495,3.129l-9.7,5.618-5,2.9-17.88,10.362-18.133,10.509a4.395,4.395,0,0,1-3.087-.061l-21.453-12.339-4.382,7.432,19.28,11.1c.638.362,1.206.684,1.672.946.722.4,1.214.675,1.387.759a12.528,12.528,0,0,0,5.118,1.053,12.89,12.89,0,0,0,4.72-.888l52.667-30.5a12.876,12.876,0,0,0,4.962-9.7Z" transform="translate(-0.001)" fill="#96bedc"/>
        </g>
    </svg>
  )
}


export default Ethscan
